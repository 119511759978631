<template>
  <div>
    <vue-documents-config />
  </div>
</template>
<script>
import DocumentsConfig from '@/components/documentsConfig/Index.vue'

export default {
  components: {
    'vue-documents-config': DocumentsConfig,
  },
}
</script>
