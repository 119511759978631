<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="lg"
  >
    <template #modal-header>
      <h5>{{ title }}</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="TypedocumentForm"
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Nombre"
                    label-for="typedocument-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      vid="nombre"
                      rules="required"
                    >
                      <b-form-input
                        id="typedocument-name"
                        v-model="name"
                        placeholder="Nombre documento"
                        :state="errors.length > 0 ? false:null"
                        name="typedocument-name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Descripción"
                    label-for="typedocument-descripcion"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Descripción"
                    >
                      <b-form-input
                        id="typedocument-descripcion"
                        v-model="description"
                        placeholder="descripción"
                        :state="errors.length > 0 ? false:null"
                        name="typedocument-descripcion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Tipo de estudio"
                    label-for="typedocument-descripcion"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Tipo de estudio"
                    >
                      <v-select
                        v-model="tipo_estudio_id"
                        name="typedocument-tipo-estudio-id"
                        label="name"
                        :options="rowsTypeStudy"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Pais"
                    label-for="ciudad-config-id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Pais"
                    >
                      <v-select
                        v-model="pais_id"
                        name="ciudad-config-id"
                        label="name"
                        :options="rowsPais"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="¿ Activar ?"
                    label-for="typedocument-is-active"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Activo"
                    >
                      <b-form-checkbox
                        v-model="is_active"
                        name="typedocument-is-active"
                        checked="false"
                        class="custom-control-primary"
                        switch
                        value="1"
                        unchecked-value="0"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="¿ Requiere ser traducido ?"
                    label-for="typedocument-is-active"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name=""
                    >
                      <b-form-checkbox
                        v-model="is_translator"
                        name="typedocument-is-active"
                        checked="false"
                        class="custom-control-primary"
                        switch
                        value="1"
                        unchecked-value="0"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Documento de ejemplo"
                    label-for="typedocument-is-active"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :rules="ruleFile"
                      name="Documento de ejemplo"
                    >
                      <b-form-file
                        ref="file-input"
                        v-model="file"
                        class="mb-2"
                        placeholder="Selecciona un archivo"
                        accept="application/pdf"
                      />
                      <iframe
                        v-if="file || getFilePath"
                        height="400px"
                        width="100%"
                        :src="`${getFilePath}`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, ext,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer, BFormCheckbox, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      name: null,
      description: null,
      tipo_estudio_id: null,
      is_active: 0,
      is_translator: 0,
      pais_id: null,
      id: null,
      value: [],
      options: [],
      rowsTypeStudy: null,
      permissionsByRole: null,
      file: null,
      getFilePath: null,
      ruleFile: 'required|ext:pdf',
      // validation rules
      required,
      ext,
    }
  },
  computed: {
    title() {
      return this.infoData ? 'Editar' : 'Crear'
    },
  },
  watch: {
    file(val) {
      if (val) {
        this.getFilePath = URL.createObjectURL(val)
      }
    },
    infoData(val) {
      this.clear()
      if (Object.keys(val).length > 0) {
        this.name = val.name
        this.description = val.description
        this.id = val.id
        this.is_active = val.is_active
        this.is_translator = val.is_translator
        this.tipo_estudio_id = val.TipoEstudio
        this.pais_id = val.pais
        this.getFilePath = `${process.env.VUE_APP_URL_API}/documents_example/${val.ExampleDocument.archivo}`
        this.ruleFile = ''
      }
    },
  },
  created() {
    this.getTipoStudio()
    this.getPais()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clear() {
      this.name = null
      this.description = null
      this.id = null
      this.getFilePath = null
      this.file = null
      this.is_active = 0
      this.is_translator = 0
      this.tipo_estudio_id = null
      this.pais_id = null
      this.file = null
      this.ruleFile = 'required|ext:pdf'
    },
    getTipoStudio() {
      this.isLoading = true
      this.$http.get('/v1/tipo-studio/get/')
        .then(res => {
          this.isLoading = false
          this.rowsTypeStudy = res.data
        })
    },
    getPais() {
      this.isLoadingPais = true
      this.$http.get('/v1/config-pais/get/status')
        .then(res => {
          this.isLoadingPais = false
          this.rowsPais = res.data
        })
    },
    save() {
      this.$refs.TypedocumentForm.validate().then(success => {
        if (success) {
          if (Object.keys(this.infoData).length > 0) {
            const data = new FormData()
            data.append('name', this.name)
            data.append('description', this.description)
            data.append('tipo_estudio_id', this.tipo_estudio_id.id)
            data.append('is_active', this.is_active)
            data.append('is_translator', this.is_translator)
            data.append('pais_id', this.pais_id.id)
            data.append('archivo', this.file)
            this.$http.put(`/v1/type-document/update/${this.id}`, data).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('edit-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          } else {
            const data = new FormData()
            data.append('name', this.name)
            data.append('description', this.description)
            data.append('tipo_estudio_id', this.tipo_estudio_id.id)
            data.append('is_active', this.is_active)
            data.append('is_translator', this.is_translator)
            data.append('pais_id', this.pais_id.id)
            data.append('archivo', this.file)
            this.$http.post('/v1/type-document/create', data).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('create-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'SUBIR';
}
</style>
