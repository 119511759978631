var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":"Login","no-close-on-esc":"","no-close-on-backdrop":"","centered":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.title))])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Enviar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cerrar ")])],1)]},proxy:true}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"TypedocumentForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"typedocument-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"typedocument-name","placeholder":"Nombre documento","state":errors.length > 0 ? false:null,"name":"typedocument-name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Descripción","label-for":"typedocument-descripcion"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"typedocument-descripcion","placeholder":"descripción","state":errors.length > 0 ? false:null,"name":"typedocument-descripcion"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de estudio","label-for":"typedocument-descripcion"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tipo de estudio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"typedocument-tipo-estudio-id","label":"name","options":_vm.rowsTypeStudy,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_estudio_id),callback:function ($$v) {_vm.tipo_estudio_id=$$v},expression:"tipo_estudio_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Pais","label-for":"ciudad-config-id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Pais"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"ciudad-config-id","label":"name","options":_vm.rowsPais,"placeholder":"Seleccione una opción"},model:{value:(_vm.pais_id),callback:function ($$v) {_vm.pais_id=$$v},expression:"pais_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"¿ Activar ?","label-for":"typedocument-is-active"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Activo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"name":"typedocument-is-active","checked":"false","switch":"","value":"1","unchecked-value":"0"},model:{value:(_vm.is_active),callback:function ($$v) {_vm.is_active=$$v},expression:"is_active"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"¿ Requiere ser traducido ?","label-for":"typedocument-is-active"}},[_c('validation-provider',{attrs:{"rules":"required","name":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"name":"typedocument-is-active","checked":"false","switch":"","value":"1","unchecked-value":"0"},model:{value:(_vm.is_translator),callback:function ($$v) {_vm.is_translator=$$v},expression:"is_translator"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Documento de ejemplo","label-for":"typedocument-is-active"}},[_c('validation-provider',{attrs:{"rules":_vm.ruleFile,"name":"Documento de ejemplo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"file-input",staticClass:"mb-2",attrs:{"placeholder":"Selecciona un archivo","accept":"application/pdf"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),(_vm.file || _vm.getFilePath)?_c('iframe',{attrs:{"height":"400px","width":"100%","src":("" + _vm.getFilePath)}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }